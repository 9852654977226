import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setToast } from 'src/redux/toast';

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
}));

const StyledForm = styled('div')(({ theme }) => ({
    backgroundColor: 'rgba(255, 255, 255, 0.93)',
    paddingInline: 20,
    paddingTop: 35,
    paddingBottom: 44,
    marginTop: 60,
    width: '30%',
    borderRadius: 9,
    [theme.breakpoints.down('lg')]: {
        width: '50%',
    },
    [theme.breakpoints.down('md')]: {
        width: '60%',
    },
    [theme.breakpoints.down('sm')]: {
        width: '80%',
    },
}));

const ContactUs = () => {
    const dispatch = useDispatch()
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [messageError, setMessageError] = useState(false);

    const handleNameChange = (e) => {
        setUserName(e.target.value);
        setNameError(false);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError(false);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
        setMessageError(false);
    };

    const handleClick = () => {
        if (!userName.trim() && !message.trim() && email == '') {
            setNameError(true);
            setMessageError(true);
            setEmailError(true)
            return;
        }
        if (!validateEmail(email)) {
            setEmailError(true);
            return;
        }
        dispatch(setToast({ message: "Message send successfully", severity: "success", show: true }))

    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    return (
        <div className="" style={{
            background: `url('/assets/illustrations/illustration_login.png')`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '100vh',
        }}>
            <StyledSection>
                <h1 style={{ color: '#000', marginTop: 50 }}>Contact Us</h1>
                <StyledForm>
                    <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img style={{ width: "200px" }} src='/assets/logo.png' alt='logo' />
                    </div>
                    <div style={{ marginTop: 25 }}>
                        <TextField
                            style={{ width: '100%' }}
                            value={userName}
                            onChange={handleNameChange}
                            name="name"
                            label="Name"
                            error={nameError}
                            helperText={nameError ? 'Name is required' : ''}
                        />
                    </div>
                    <div style={{ marginTop: 30 }}>
                        <TextField
                            style={{ width: '100%' }}
                            value={email}
                            onChange={handleEmailChange}
                            name="email"
                            label="Email"
                            error={emailError}
                            helperText={emailError && email == '' ? 'Email is required' :emailError && email!=''? "Invalid email" : ''}
                        />
                    </div>
                    <div style={{ marginTop: 30 }}>
                        <TextField
                            style={{ width: '100%' }}
                            value={message}
                            onChange={handleMessageChange}
                            name="message"
                            label="Message"
                            error={messageError}
                            helperText={messageError ? 'Message is required' : ''}
                        />
                    </div>
                    <div style={{ marginInline: 'auto', width: '50%', marginTop: 30 }}>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={handleClick}
                        >
                            Submit
                        </LoadingButton>
                    </div>
                </StyledForm>
            </StyledSection>
        </div>
    );
};

export default ContactUs;
