import { create } from 'apisauce'
import { base_url } from './endpoints'

export const api = create({
    baseURL: base_url
})

const hit = async (endpoint = "", method = "GET", body = {},axiosConfig={}) => {
    let x = ""
    const accessToken = localStorage.getItem("@token");
    if (accessToken) {
        api.headers['Authorization'] = `bearer ${accessToken}`;
    }
    switch (method.toLowerCase()) {
        case "get":
            x = await api.get(endpoint, body)
            break
        case "post":
            x = await api.post(endpoint, body)
            break
        case "patch":
            x = await api.patch(endpoint, body)
            break
        case "delete":
            x = await api.delete(endpoint, body)
            break
    }
    switch (x.problem) {
        case null:
            return { err: false, data: x.data, status: x.status }
        case "CLIENT_ERROR":
            return { err: true, msg: x.data.message, status: x.status }
        case "SERVER_ERROR":
            return { err: true, msg: "SERVER_ERROR", status: x.status }
        default:
            return { err: true, msg: x.problem, status: x.status }
    }
}

export default hit