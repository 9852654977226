import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const users=useSelector(state=>state.users)?.list
  const orders=useSelector(state=>state.orders)?.list
  const navs=useSelector(state=>state.navs)
  const user=useSelector(state=>state.auth)?.user
  const navigate=useNavigate()
  useEffect(()=>{
    if(navs&&user?.role=="staff"){
      if(navs?.read?.filter(x=>x=="dashboard")?.length==0){
        if(navs?.navConfig[0]){
          navigate(navs?.navConfig[0]?.path,{replace:true})
        }
      }
    }
  },[user])
  return (
    <>  
      <Helmet>
        <title> Dashboard | Lugit</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
           <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Users" total={users?.length} color="secondary" icon={'ant-design:bug-filled'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Android Users" total={users?.filter(x=>x.os=="android").length} icon={'ant-design:android-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="IOS Users" total={users?.filter(x=>x.os=="ios").length??"0"} color="info" icon={'ant-design:apple-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Packages" total={orders?.length} color="warning" icon={'mdi:package-outline'} />
          </Grid>

         

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="App logged in"
              // subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'IOS',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Android',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Total Users"
              chartData={[
                { label: 'IOS', value: users?.filter(x=>x.os=="ios").length??0 },
                { label: 'Android', value: users?.filter(x=>x.os=="android").length??0 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <AppNewsUpdate
              title="New Packages"
              list={orders?.slice(0,5).map((item, index) => ({
                id: faker.datatype.uuid(),
                title: item?.from?.address+"-"+item?.to?.address,
                description: `Shipper: ${item?.requested_by?.email}`,
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: moment(item?.createdAt).fromNow(),
              }))}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
