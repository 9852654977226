import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, Badge, Button } from '@mui/material';
// component
import Iconify from '../../../components/iconify';
import CustomDropDown from 'src/components/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setToast } from 'src/redux/toast';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function UserListToolbar({ numSelected,headerText, showInput, show, show1, filterName, onFilterName, placeholder, FilterComp, showFilter }) {
  const users = useSelector(state => state.users)?.list
  const [usersV, setUsersV] = useState([])
  const navigate = useNavigate()
  const user = useSelector(state => state.auth)?.user
  const navs = useSelector(state => state.navs)?.write
  const dispatch = useDispatch()
  useEffect(() => {
    setUsersV(users?.filter(x => x.transportStatus == "submitted"))
  }, [users])
  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        showInput == false ? <Typography variant='h5'>{headerText}</Typography> : <StyledSearch
          value={filterName}
          onChange={onFilterName}
          placeholder={placeholder ?? "Search Users..."}
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}
      {show1 && <Badge badgeContent={usersV?.length} color="primary">
        <Button onClick={() => {
          if (user?.role == "admin") {
            navigate("/dashboard/verifications")
          } else {
            if (navs.includes("users")) {
              navigate("/dashboard/verifications")
            } else {
              dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))
            }
          }
        }}>
          User Verifications
        </Button>
      </Badge>}
      {show && <Badge badgeContent={usersV?.length} color="primary">

        <Button onClick={() => {
          if (user?.role == "admin") {
            navigate("/dashboard/verifications")
          } else {
            if (navs.includes("users")) {
              navigate("/dashboard/verifications")
            } else {
              dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))
            }
          }
        }}>
          User Verifications
        </Button>
      </Badge>}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
          </IconButton>
        </Tooltip>
      ) : (
        null
      )}
      {showFilter &&
        <FilterComp />
      }
    </StyledRoot>
  );
}
