// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
    v: "dashboard"
  },
  {
    title: 'Users',
    path: '/dashboard/user',
    icon: icon('ic_user'),
    v: "users"
  },
  {
    title: 'Activities',
    path: '/dashboard/activity',
    icon: icon('ic_user'),
    v: "activities"
  },
  {
    title: 'Orders',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
    v: "Orders"
  },
  {
    title: 'Subscriptions',
    path: '/dashboard/subs',
    icon: icon('ic_blog'),
    v: "subs"
  },
  {
    title: 'Crypto Addresses',
    path: '/dashboard/crypto_addresses',
    icon: icon('ic_user'),
    v: "crypto_addresses"
  },
  {
    title: 'Crypto Payouts',
    path: '/dashboard/crypto_payouts',
    icon: icon('ic_user'),
    v: "crypto_payouts"
  },
  {
    title: 'Pages',
    path: '/dashboard/blog',
    icon: icon('ic_blog'),
    v: "pages"
  },
  {
    title: 'Intros',
    path: '/dashboard/intros',
    icon: icon('ic_blog'),
    v: "intros"
  },
  {
    title: 'Settings',
    path: '/dashboard/settings',
    icon: icon('ic_lock'),
    v: "settings"
  },
  {
    title: 'Contact Us',
    path: '/dashboard/supports',
    icon: icon('ic_help'),
    v: "supports"
  },

  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
