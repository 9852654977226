import { Navigate, Outlet, useNavigate, useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import OrdersPage from './pages/Orders';
import SubsPage from './pages/SubsPage';
import SettingPage from './pages/SettingPage';
import SupportsPage from './pages/SupportPage';
import UserHistoryPage from './pages/UserHistoryPage';
import IntroPage from './pages/IntrosPage';
import UserVerfications from './pages/UserVerifications';
import ContactUs from './pages/ContactUs';
import Activity from './pages/Activity';
import CryptoAddress from './pages/CryptoAddress';
import CryptoPayouts from './pages/CryptoPayouts';

// ----------------------------------------------------------------------

export default function Router() {
  const navigate = useNavigate()
  const auth = useSelector(state => state.auth)

  useEffect(() => {
    let token = localStorage.getItem('@token')
    if (auth?.authorize||token) {
      <Outlet/>
      // navigate("/dashboard", { replace: true })
    } else {
      navigate("/login", { replace: true })
    }
  }, [auth])
  const routes = useRoutes([
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/contact_us',
      element: <ContactUs/>,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <OrdersPage /> },
        { path: 'activity', element: <Activity /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'subs', element: <SubsPage /> },
        { path: 'crypto_addresses', element: <CryptoAddress /> },
        { path: 'crypto_payouts', element: <CryptoPayouts /> },
        { path: 'settings', element: <SettingPage /> },
        { path: 'supports', element: <SupportsPage /> },
        { path: 'history', element: <UserHistoryPage /> },
        { path: 'intros', element: <IntroPage /> },
        { path: 'verifications', element: <UserVerfications /> },

      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
