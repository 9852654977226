import { createSlice } from '@reduxjs/toolkit'
import hit from 'src/services/manager'

const initialState = {
    list: [],
    sortOrder: {
        orderBy: 'source',
        ascDesc: 'asc',
    },
}

const activitySlice = createSlice({
    name: "acitvity",
    initialState,
    reducers: {
        setAcivityList: (state, action) => {
            state.list = action.payload
        },
        setSortActivity: (state, action) => {
            state.sortOrder = {
                orderBy: action.payload?.orderBy || 'source',
                ascDesc: action.payload?.ascDesc || 'asc',
            };
        },

    }
})

export const { setAcivityList,setSortActivity } = activitySlice.actions


export const getActivity = (data) => {
    return async (dispatch) => {
        try {
            let payload={ type: data?.activeStatus }
            let res = {}
            if(data?.activeStatus=='all')
            {
                payload={}
            }
            if(data?.type==='shipper'){
                res = await hit("/admin/shipper_list_active", "post", payload)
            }else if(data?.type==='transporter'){
                res = await hit("/listings/active_expired_route", "post", payload)
            }
            if (!res.err) {
                dispatch(setAcivityList(res?.data))
            }
        } catch (err) {

        } finally {

        }
    }
}

export const sortActivityData = (data) => {
    return async (dispatch) => {
        try {
            dispatch(setSortActivity(data));
        } catch (err) {
            // Handle errors
        } finally {
            // Cleanup if needed
        }
    };
};

export default activitySlice.reducer