import { Provider } from 'react-redux'

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { store } from './redux';
import CustomToast from './components/toast';
import { useEffect } from 'react';
import { setAuthorize } from './redux/auth';
import Loader from './components/loader';
// ----------------------------------------------------------------------

export default function App() {

  useEffect(() => {
    const rememberAuth = localStorage.getItem("@remember")
    if (rememberAuth === "yes") {
      store.dispatch(setAuthorize(true))
    }
  }, [])
  return (
    <Provider store={store} >
      <ThemeProvider>
        <ScrollToTop />
        <StyledChart />
        <Router />
      </ThemeProvider>
      <CustomToast />
      <Loader />
    </Provider>
  );
}
