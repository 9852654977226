import { createSlice } from '@reduxjs/toolkit';
import hit from 'src/services/manager';

const initialState = {
  list: [],
  sortOrder: {
    orderBy: 'firstName',
    ascDesc: 'asc',
  },
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
    setSortOrder: (state, action) => {
      state.sortOrder = {
        orderBy: action.payload?.orderBy || 'firstName',
        ascDesc: action.payload?.ascDesc || 'asc',
      };
    },
  },
});

export const { setList, setSortOrder } = usersSlice.actions;

export const getAllUsers = () => {
  return async (dispatch) => {
    try {
      let res = await hit('/admin/users', 'get');
      if (!res?.err) {
        dispatch(setList(res.data));
      }
    } catch (err) {
      // Handle errors
    } finally {
      // Cleanup if needed
    }
  };
};

export const convertAmountInCrypto = (data, callback) => {
  return async (dispatch) => {
    let res = await hit('/crypto/price_convert', 'post', data);
    callback(res)
  }
};

export const UpdateOrderTrxId = (orderId, data, callback) => {
  return async (dispatch) => {
    let res = await hit(`/admin/order/${orderId}`, 'patch', data);
    callback(res)
  }
};

export const notifyUser = (data, callback) => {
  return async (dispatch) => {
    let res = await hit('/users/notifyUser', 'post', data);
    callback(res)
  }
};

export const sortOrderData = (data) => {
  return async (dispatch) => {
    try {
      dispatch(setSortOrder(data));
    } catch (err) {
      // Handle errors
    } finally {
      // Cleanup if needed
    }
  };
};

export default usersSlice.reducer;
