import { configureStore } from '@reduxjs/toolkit'

import auth from './auth'
import orders from './orders'
import users from './users'
import miscdata from './miscdata'
import toast from './toast'
import loader from './loader'
import supports from './support'
import navs from './navs'
import faqs from './faq'
import activity from './activity'
import addressCrypto from './addressCrypto'

const store = configureStore({
    reducer: {
        auth,
        orders,
        users,
        miscdata,
        toast,
        loader,
        supports,
        navs,
        faqs,
        activity,
        addressCrypto
    }
})

export { store }