import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Dialog,
    DialogTitle,
    TextField,
    Box,
} from '@mui/material';
// components
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, } from '../sections/@dashboard/user';
// mock
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from 'src/redux/toast';
import { getOrdersListThunk, sortOrderData } from 'src/redux/orders';
import { convertAmountInCrypto, notifyUser, UpdateOrderTrxId } from '../redux/users';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'trackId', label: 'Track ID', alignRight: false },
    { id: 'transporter_name', label: 'Transporter Name', alignRight: false },
    { id: 'order_status', label: "Order Status", alignRight: false },
    { id: 'received_amount', label: "Received Amount", alignRight: false },
    { id: 'payment_method', label: "Payment Method", alignRight: false },
    { id: 'action', label: "Action", alignRight: false },
];

export default function CryptoPayouts() {
    const orders = useSelector(state => state.orders)?.list
    const [listedOrders, setListedOrders] = useState([])
    const [priceInCrypto, setPriceInCrypto] = useState(0)
    const [recTrxId, setRecTrxId] = useState("")

    const convertInCrypto = async (amount, symbol) => {
        symbol = symbol == "Ethereum" ? "ETH" : "SOL"
        let data = {
            amount: amount.toString(),
            convert: symbol
        }
        const callback = (resp) => {
            if (resp?.err) {
                dispatch(setToast({ show: true, message: "Error While Converting Payment", severity: "error" }))
            }
            let response = parseFloat(resp?.data[symbol].price).toFixed(8)
            setPriceInCrypto(response)
        }
        dispatch(convertAmountInCrypto(data, callback))
    }

    useEffect(() => {
        dispatch(getOrdersListThunk('all'))
    }, [])

    useEffect(() => {
        let newOrderList = []
        orders?.map((order) => {
            if(order?.paymentStatus == 1 && (order?.payment_mode == 'Solana' || order?.payment_mode == "Ethereum") && (order?.status == 4 || order?.status == 5) && order?.transporter_trx_id == "") {
                newOrderList.push(order)
            }
        })
        setListedOrders(newOrderList)
    }, [orders])

    const [open, setOpen] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(200);
    const [selectedItem, setSelectedItem] = useState(null)
    const dispatch = useDispatch()


    const markAsPaidToTransporter = () => {
        if (recTrxId == "") {
            dispatch(setToast({ show: true, message: "Please Mention Transaction ID", severity: "error" }))
            return false
        }
        let orderId = selectedItem?._id
        let item = selectedItem
        let data = {
            transporter_trx_id: recTrxId
        }
        const callback = (response) => {
            if (!response?.err) {
                item = {
                    ...item,
                    transporter_trx_id: recTrxId
                }
                // notify to User
                let notifData = {
                    title: "Message From Admin",
                    message: "Admin has Paid your Amount in Crypto as you submit your default Payment Method",
                    userId: item?.requested_to?._id,
                    notiType: 0
                }
                const notifCallback = () => {
                    dispatch(setToast({ show: true, message: "Transaction Marked as Paid Successfully", severity: "success" }))
                    setOpen(false)
                    dispatch(getOrdersListThunk('all'))
                }
                dispatch(notifyUser(notifData, notifCallback))
            }
        }
        dispatch(UpdateOrderTrxId(orderId, data, callback))
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listedOrders?.length) : 0;

    const OrderStatus = ["Requested", "Accepted", "Rejected", "Picked Up", "Delieverd", "Received", "Cancelled"]

    return (
        <>
            <Helmet>
                <title>Activity | Lugit</title>
            </Helmet>
            <div className="" style={{ paddingInline: "20px" }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Crypto Payouts
                    </Typography>
                </Stack>
                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={listedOrders.length}
                                />
                                <TableBody>
                                    {listedOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        const { _id, trackId, requested_to, status, subTotal, pickupCharges } = row;
                                        return (
                                            <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                                                <TableCell padding="checkbox" sx={{ paddingLeft: "15px" }}>
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    {trackId}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {requested_to?.firstName +" "+ requested_to?.lastName}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {OrderStatus[status]}
                                                </TableCell>
                                                <TableCell align="left">
                                                    ${subTotal+pickupCharges}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {requested_to?.payment_method +"-"+ requested_to?.payment_mode}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 10 }}>
                                                        <Button onClick={() => {
                                                            { setSelectedItem(row); setOpen(true); convertInCrypto(subTotal+pickupCharges,requested_to?.payment_mode)}
                                                        }} variant='contained' color={'error'}>View Details</Button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>


                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100, 200]}
                        component="div"
                        count={listedOrders.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
                {open &&
                    <Dialog
                        fullWidth
                        open={open}
                        onClose={() => { setOpen(false) }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">View Trx Detail and Mark As Paid</DialogTitle>
                        <div style={{padding:20}}>
                        <Box>
                            <p><strong>Transporter Crypto Receiving Address</strong><br />
                                <span>{selectedItem?.requested_to?.payment_mode == "Solana"?selectedItem?.requested_to?.solana_recieving_address:selectedItem?.requested_to?.eth_recieving_address}</span>
                            </p>
                            <p><strong>Amount in Crypto</strong><br />
                                <span>{priceInCrypto}</span>
                            </p>
                            <div className='form-group'>
                                <TextField style={{ width: "500px" }} value={recTrxId} onChange={e => setRecTrxId(e.target.value)} name="recTrxId" label="Receiver TrxId" />
                                <Button onClick={() => markAsPaidToTransporter()} variant='contained' style={{ height: "55px", marginTop: "10px" }}>Mark as Paid</Button>
                            </div>
                            <small>Note: This action will notify to user that your amount has been initiated by admin</small>
                        </Box>
                        </div>
                    </Dialog>
                }
            </div>
        </>
    );
}