import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    show: false,
    message:"",
    severity:"success"
}

const toastSlice = createSlice({
    name: "toast",
    initialState,
    reducers: {
        setToast: (state, action) => {
            state.message=action.payload.message
            state.severity=action.payload.severity
            state.show = action.payload.show
        },
        closeToast:(state,action)=>{
            state.show=false
        }
    }
})

export const { setToast ,closeToast} = toastSlice.actions

export default toastSlice.reducer