import { useEffect, useRef, useState } from 'react';

import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Box, TextField } from '@mui/material';
import JoditEditor from 'jodit-react';
import { useDispatch, useSelector } from 'react-redux';
import hit from 'src/services/manager';
import { setLoader } from 'src/redux/loader';
import { setMiscData } from 'src/redux/miscdata';
import { setToast } from 'src/redux/toast';

// components
// mock

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function IntroPage() {
    const [selected, setSelected] = useState(0)
    const editor = useRef(null);
    const editor1 = useRef(null);
    const editor2 = useRef(null);

    const [terms, setTerms] = useState("")
    const [policy, setPolicy] = useState("")
    const [about, setAbout] = useState("")
    const [intro, setIntro] = useState("")
    const miscdata = useSelector(state => state.miscdata)?.data
    const dispatch = useDispatch()
    const writes = useSelector(state => state.navs)?.write
    const user = useSelector(state => state.auth)?.user

    useEffect(() => {
        if (miscdata?.terms) {
            setTerms(miscdata?.terms)
            setPolicy(miscdata?.policy)
            setAbout(miscdata?.about)
            setIntro(miscdata?.intro)
        }
    }, [miscdata])

    const config = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/,
        placeholder: 'Start typings...'
    }

    const submit = async () => {
        try {
            dispatch(setLoader(true))
            let res = await hit("/admin/misc", "post", { intro })
            if (!res.err) {
                dispatch(setMiscData(res.data))
                dispatch(setToast({ show: true, message: "Updated Successfully!", severity: "success" }))

            } else {
                dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
            }
        } catch (err) {

        } finally {
            dispatch(setLoader(false))

        }
    }

    return (
        <>
            <Helmet>
                <title> Dashboard: Pages | Lugit </title>
            </Helmet>

            <div style={{paddingInline:"20px"}}>
                <Stack direction="row" alignItems="center" mb={5}>
                    <Button color={selected == 0 ? "primary" : "secondary"} onClick={() => {
                        setSelected(0)
                    }} variant="contained" >
                       Write text for intro screen
                    </Button>

                </Stack>
                <div>
                    {/* <JoditEditor
                        ref={editor}
                        value={intro}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => setIntro(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={newContent => { }}
                    /> */}
                    <TextField
                        value={intro}
                        onChange={e => {
                            setIntro(e?.target?.value)
                        }}
                        fullWidth
                        style={{ height: "200px" }}
                        multiline
                        minRows={5}
                    />
                </div>
                <Box display="flex" justifyContent={"center"} mt={4}>
                    <Button onClick={() => {
                        if (user?.role == "admin") {
                            submit()
                        } else if (user?.role == "staff") {
                            if (writes?.includes("subs")) {
                                submit()
                            } else {
                                dispatch(setToast({ show: true, message: "Sorry you are not authorised!", severity: "error" }))

                            }
                        }
                    }} variant='contained' >
                        Submit
                    </Button>
                </Box>
            </div>
        </>
    );
}
