import { Circles } from 'react-loader-spinner'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'

export default function Loader() {
    const loader=useSelector(state=>state.loader)?.show
    return (
        <>
        {loader?<Box sx={{ height: "100vh", display: "flex",background:"#0007", justifyContent: "center", alignItems: "center", width: "100vw",position:"absolute",top:0,left:0 ,zIndex:10000}}>
            <Circles
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </Box>:null}
        </>
    )
}