import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link, Box, IconButton, InputAdornment, TextField, Checkbox, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import Iconify from '../iconify';


export default function UserPass({ title, description, open, setOpen, onSubmit, onCancel ,selected}) {
  const [showPassword, setShowPassword] = React.useState(false);
  const dispatch = useDispatch()
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [dashboard, setDashboard] = React.useState({
    read: false,
    write: false
  })
  const [users, setUsers] = React.useState({
    read: false,
    write: false
  })
  const [packages, setPackages] = React.useState({
    read: false,
    write: false
  })
  const [subs, setSubs] = React.useState({
    read: false,
    write: false
  })
  const [pages, setPages] = React.useState({
    read: false,
    write: false
  })
  const [settings, setSettings] = React.useState({
    read: false,
    write: false
  })
  const [supports, setSupports] = React.useState({
    read: false,
    write: false
  })
  React.useEffect(()=>{
      if(selected){
        setEmail(selected?.email)
        setPassword(selected?.accessPass)
        setDashboard(selected?.accessLevel?.dashboard)
        setUsers(selected?.accessLevel?.users)
        setPackages(selected?.accessLevel?.packages)
        setSubs(selected?.accessLevel?.subs)
        setPages(selected?.accessLevel?.pages)
        setSupports(selected?.accessLevel?.supports)
      }else{
        setEmail("")
        setPassword("")
        setDashboard({
          read: false,
          write: false
        })
        setUsers({
          read: false,
          write: false
        })
        setPackages({
          read: false,
          write: false
        })
        setSubs({
          read: false,
          write: false
        })
        setPages({
          read: false,
          write: false
        })
        setSupports({
          read: false,
          write: false
        })
      }
  },[open,selected])
  return (
    <Dialog
      open={open}
      onClose={() => { setOpen(false) }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
        <Box sx={{ marginTop: "20px" }}>
          <TextField value={email} onChange={e => {
            setEmail(e.target.value)
          }} name="email" label="Email address" />

          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={e => {
              setPassword(e.target.value)
            }}
            sx={{ marginLeft: "10px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <Box display={"flex"} direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 2 }}>
            <Link color={"ButtonText"} variant="subtitle2" underline="hover">
              Dashboard
            </Link>
            <Box sx={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
              <Checkbox inputProps={{ "aria-label": "View" }} onChange={(e) => {
                setDashboard({ ...dashboard, read: e.target.checked })
              }} checked={dashboard.read} name="dash" />
              <Typography>View</Typography>
              {/* <Checkbox inputProps={{ "aria-label": "Edit" }} onChange={(e) => {
                setDashboard({ read:e.target.checked??dashboard.read, write: e.target.checked })
              }} checked={dashboard.write} name="dash" />
              <Typography>Edit</Typography> */}
            </Box>
          </Box>
          <Box display={"flex"} direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 2 }}>
            <Link color={"ButtonText"} variant="subtitle2" underline="hover">
              Users
            </Link>
            <Box sx={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
              <Checkbox inputProps={{ "aria-label": "View" }} onChange={(e) => {
                setUsers({ ...users, read: e.target.checked })
              }} checked={users.read} name="dash" />
              <Typography>View</Typography>
              <Checkbox inputProps={{ "aria-label": "Edit" }} onChange={(e) => {
                setUsers({ read:e.target.checked??users.read, write: e.target.checked })
              }} checked={users.write} name="dash" />
              <Typography>Edit</Typography>
            </Box>
          </Box>
          <Box display={"flex"} direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 2 }}>
            <Link color={"ButtonText"} variant="subtitle2" underline="hover">
              Packages
            </Link>
            <Box sx={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
              <Checkbox inputProps={{ "aria-label": "View" }} onChange={(e) => {
                setPackages({ ...packages, read: e.target.checked })
              }} checked={packages.read} name="dash" />
              <Typography>View</Typography>
              {/* <Checkbox inputProps={{ "aria-label": "Edit" }} onChange={(e) => {
                setPackages({ read:e.target.checked??packages.read, write: e.target.checked })
              }} checked={packages.write} name="dash" />
              <Typography>Edit</Typography> */}
            </Box>
          </Box>
          <Box display={"flex"} direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 2 }}>
            <Link color={"ButtonText"} variant="subtitle2" underline="hover">
              Subscriptions
            </Link>
            <Box sx={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
              <Checkbox inputProps={{ "aria-label": "View" }} onChange={(e) => {
                setSubs({ ...subs, read: e.target.checked })
              }} checked={subs.read} name="dash" />
              <Typography>View</Typography>
              <Checkbox inputProps={{ "aria-label": "Edit" }} onChange={(e) => {
                setSubs({ read:e.target.checked??subs.read, write: e.target.checked })
              }} checked={subs.write} name="dash" />
              <Typography>Edit</Typography>
            </Box>
          </Box>
          <Box display={"flex"} direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 2 }}>
            <Link color={"ButtonText"} variant="subtitle2" underline="hover">
              Pages
            </Link>
            <Box sx={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
              <Checkbox inputProps={{ "aria-label": "View" }} onChange={(e) => {
                setPages({ ...pages, read: e.target.checked })
              }} checked={pages.read} name="dash" />
              <Typography>View</Typography>
              <Checkbox inputProps={{ "aria-label": "Edit" }} onChange={(e) => {
                setPages({ read:e.target.checked??pages.read, write: e.target.checked })
              }} checked={pages.write} name="dash" />
              <Typography>Edit</Typography>
            </Box>
          </Box>
          {/* <Box display={"flex"} direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 2 }}>
            <Link color={"ButtonText"} variant="subtitle2" underline="hover">
              Settings
            </Link>
            <Box sx={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
              <Checkbox inputProps={{ "aria-label": "View" }} onChange={(e) => {
                setDashboard({ ...users, read: e.target.checked })
              }} value={users.read} name="dash" />
              <Typography>View</Typography>
              <Checkbox inputProps={{ "aria-label": "Edit" }} onChange={(e) => {
                setDashboard({ ...users, write: e.target.checked })
              }} value={users.write} name="dash" />
              <Typography>Edit</Typography>
            </Box>
          </Box> */}
          <Box display={"flex"} direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 2 }}>
            <Link color={"ButtonText"} variant="subtitle2" underline="hover">
              Supports
            </Link>
            <Box sx={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
              <Checkbox inputProps={{ "aria-label": "View" }} onChange={(e) => {
                setSupports({ ...supports, read: e.target.checked })
              }} checked={supports.read} name="dash" />
              <Typography>View</Typography>
              {/* <Checkbox inputProps={{ "aria-label": "Edit" }} onChange={(e) => {
                setSupports({ read:e.target.checked??supports.read, write: e.target.checked })
              }} checked={supports.write} name="dash" />
              <Typography>Edit</Typography> */}
            </Box>
          </Box>
        </Box>
       
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={()=>{
          if(email?.trim()==""||password?.trim()==""){
            return
          }
          if(dashboard?.read==false&&users?.read==false&&packages?.read==false&&subs?.read==false&&pages?.read==false&&supports?.read==false){
            return
          }
          onSubmit({
          email,
          password,
          accessLevel:{
            dashboard,
            users,
            packages,
            subs,
            settings,
            pages,
            supports
          }
        },selected)}} autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}