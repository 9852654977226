import { createSlice } from '@reduxjs/toolkit'
import hit from 'src/services/manager'

const initialState = {
    list: [],
    sortOrder: {
        orderBy: 'name',
        ascDesc: 'asc',
    },
}

const supportSlice = createSlice({
    name: "support",
    initialState,
    reducers: {
        setSupportList: (state, action) => {
            state.list = action.payload
        },
        setSortOrder: (state, action) => {
            state.sortOrder = {
                orderBy: action.payload?.orderBy || 'name',
                ascDesc: action.payload?.ascDesc || 'asc',
            };
        },
    }
})

export const { setSupportList,setSortOrder } = supportSlice.actions

export const getSupportsThunk = () => {
    return async (dispatch) => {
        try {
            let res = await hit("/supports", "get")
            if (!res.err) {
                dispatch(setSupportList(res?.data))
            }
        } catch (err) {

        } finally {

        }
    }
}

export const sortOrderData = (data) => {
    return async (dispatch) => {
        try {
            dispatch(setSortOrder(data));
        } catch (err) {
            // Handle errors
        } finally {
            // Cleanup if needed
        }
    };
};

export default supportSlice.reducer