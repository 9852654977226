import {createSlice} from '@reduxjs/toolkit'
import hit from 'src/services/manager'

const initialState={
    data:{}
}

const miscDataSlice=createSlice({
    name:"miscData",
    initialState,
    reducers:{
        setMiscData:(state,action)=>{
            state.data=action.payload
        }
    }
})

export const {setMiscData}=miscDataSlice.actions

export const getMiscDataThunk=()=>{
    return async(dispatch)=>{
        try{
            let res=await hit("/admin/misc","get")
            if(!res.err){
                dispatch(setMiscData(res?.data))
            }
        }catch(err){

        }finally{

        }
    }
}
export default miscDataSlice.reducer