import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { setAuthorize } from '../../../redux/auth';

// components
import Iconify from '../../../components/iconify';
import hit from 'src/services/manager';
import { setToast } from 'src/redux/toast';
import { setLoader } from 'src/redux/loader';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch()
  const [rem, setRem] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const handleClick = async () => {
    try {
      dispatch(setLoader(true))
      let res = await hit("/auth/login-admin", "post", { email, password })
      if (!res.err) {
        localStorage.setItem("@token", res.data?.tokens?.access?.token)
        localStorage.setItem("@remember", rem ? "yes" : "no")
        navigate('/dashboard')
        dispatch(setAuthorize(true))
        dispatch(setToast({ message: "Login Successfully", severity: "success", show: true }))
      } else {
        dispatch(setToast({ message: res.msg, severity: "error", show: true }))
      }
    } catch (err) {

    } finally {
      dispatch(setLoader(false))
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField value={email} onChange={e => {
          setEmail(e.target.value)
        }} name="email" label="Email address" />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={e => {
            setPassword(e.target.value)
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 2 }}>
        <Checkbox onChange={(e) => {
          setRem(e.target.checked)
        }} value={rem} name="remember" />
        <Link color={"ButtonText"} variant="subtitle2" underline="hover">
          Remember me
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
