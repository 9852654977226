import { createSlice } from '@reduxjs/toolkit'
import hit from 'src/services/manager'
import { setLoader } from './loader'

const initialState = {
    list: [],
}

const cryptoAdressSlice = createSlice({
    name: "cryptoAddress",
    initialState,
    reducers: {
        setCryptoAddressList: (state, action) => {
            state.list = action.payload
        },

    }
})

export const { setCryptoAddressList } = cryptoAdressSlice.actions


export const getCryptoAdresses = (data) => {
    return async (dispatch) => {
        try {
            dispatch(setLoader(true))
            let payload = data?.type
            let res = {}
            if (data?.type === 'all') {
                payload = ''
            }
            if (data?.type) {
                res = await hit(`/crypto/get_crypto_address?type=${payload}`, "get",)
            }
            if (!res.err) {
                dispatch(setLoader(false))
                dispatch(setCryptoAddressList(res?.data))
            }
        } catch (err) {
            dispatch(setLoader(false))

        } finally {

            dispatch(setLoader(false))
        }
    }
}

export default cryptoAdressSlice.reducer