import {createSlice} from '@reduxjs/toolkit'

const navsSlice=createSlice({
    name:"navs",
    initialState:{
        read:[],
        write:[],
        navConfig:[]
    },
    reducers:{
        setReadNavData:(state,action)=>{
            state.read=action.payload
        },
        setWriteNavData:(state,action)=>{
            state.write=action.payload
        },
        setNavConfig:(state,action)=>{
            state.navConfig=action.payload
        }
    }
})

export const {setReadNavData,setWriteNavData,setNavConfig}=navsSlice.actions

export default navsSlice.reducer