import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { closeToast } from '../../redux/toast';

const Alert = React.forwardRef((
    props,
    ref,
) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomToast = () => {
    const toast = useSelector(state => state.toast)
    const dispatch = useDispatch()
    return (<Snackbar open={toast?.show} autoHideDuration={4000} onClose={() => {
        dispatch(closeToast())
    }}>
        <Alert onClose={() => {
            dispatch(closeToast())
        }} severity={toast?.severity} sx={{ width: '100%' }}>
            {toast?.message}
        </Alert>
    </Snackbar>)
}

export default CustomToast